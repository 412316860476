import { useDynamicSaga } from '@/core/applicationState/hooks/useDynamicSaga';
import { useUserTiming } from '@/core/observability/utils/useUserTiming';
import { useFlags } from '@/domains/core/flags/flags.hooks';
import { HP_B2C_BLACKFRIDAY_UPPER_PROMO } from '@/domains/proB2BAnimation/featureFlags';
import { campaignImageTrackingSaga } from '@/productDiscovery/CommercialAnimation/components/CampaignImage/CampaignImage.saga.client';
import { HomepageCampaigns } from '@/productDiscovery/CommercialAnimation/components/HomepageCampaigns/HomepageCampaigns';
import { SecondaryPromotionsContainer } from '@/productDiscovery/CommercialAnimation/components/SecondaryPromotionsContainer/SecondaryPromotionsContainer';
import { usePromotedProducts } from '@/retention/Homepage/b2c/hooks/usePromotedProducts';
import { useSuggestedCategories } from '@/retention/Homepage/b2c/hooks/useSuggestedCategories';
import { Advice } from '@/retention/Homepage/b2c/modules/Advice/Advice';
import { Brands } from '@/retention/Homepage/b2c/modules/Brands/Brands';
import { HomepageMeta } from '@/retention/Homepage/b2c/modules/HomepageMeta/HomepageMeta';
import { PromotedProducts } from '@/retention/Homepage/b2c/modules/PromotedProducts/PromotedProducts.lazy';
import { LazyReassurance } from '@/retention/Homepage/b2c/modules/Reassurance/LazyReassurance';
import { Seo } from '@/retention/Homepage/b2c/modules/Seo/Seo';
import { SuggestedAndRecentlyViewedProducts } from '@/retention/Homepage/b2c/modules/SuggestedAndRecentlyViewedProducts/SuggestedAndRecentlyViewedProducts';
import { SuggestedCategories } from '@/retention/Homepage/b2c/modules/SuggestedCategories/SuggestedCategories.lazy';

import styles from './container.module.scss';

export const Container = () => {
  useUserTiming('homepageB2C');
  useDynamicSaga('trackingCampaignImageImpression', campaignImageTrackingSaga);
  const promotedProducts = usePromotedProducts() || [];
  const suggestedCategories = useSuggestedCategories() || [];
  const [isHPB2CBlackfridayUpperPromo] = useFlags([
    HP_B2C_BLACKFRIDAY_UPPER_PROMO,
  ]);
  return (
    <main className={styles.main}>
      <HomepageMeta />
      <HomepageCampaigns />
      {isHPB2CBlackfridayUpperPromo && promotedProducts.length > 0 && (
        <PromotedProducts products={promotedProducts} />
      )}
      <SuggestedAndRecentlyViewedProducts />
      {!isHPB2CBlackfridayUpperPromo && (
        <SecondaryPromotionsContainer className={styles.secondaryPromotions} />
      )}
      {!isHPB2CBlackfridayUpperPromo && promotedProducts.length > 0 && (
        <PromotedProducts products={promotedProducts} />
      )}
      {suggestedCategories.length > 0 && (
        <SuggestedCategories categories={suggestedCategories} />
      )}
      <Advice />
      <LazyReassurance />
      <Brands />
      <Seo />
    </main>
  );
};
