import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';
import LazyHydrate from 'react-lazy-hydration';

import { generateLoadingByElementId } from '@/core/lazyHydrate/generateLoadingByElementId';

const DynamicSecondaryPromotions = dynamic<React.PropsWithChildren<unknown>>(
  () =>
    import(
      '@/productDiscovery/CommercialAnimation/components/SecondaryPromotions/SecondaryPromotions'
    ).then((mod) => mod.SecondaryPromotions),
  {
    ssr: false,
    loading: () => generateLoadingByElementId('secondary-promotions'),
  },
);

export const LazySecondaryPromotions: FunctionComponent = () => (
  <LazyHydrate whenVisible id="secondary-promotions">
    <DynamicSecondaryPromotions />
  </LazyHydrate>
);

LazySecondaryPromotions.displayName = 'LazySecondaryPromotions';
