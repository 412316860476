import classnames from 'classnames';

import { useFlags } from '@/domains/core/flags/flags.hooks';
import { HP_B2C_BLACKFRIDAY_UPPER_PROMO } from '@/domains/proB2BAnimation/featureFlags';
import { HomepageTertiaryCampaignsSection } from '@/productDiscovery/CommercialAnimation/components/HomepageCampaigns/HomepageTertiaryCampaigns/HomepageTertiaryCampaignsSection';
import { SecondaryPromotionsContainer } from '@/productDiscovery/CommercialAnimation/components/SecondaryPromotionsContainer/SecondaryPromotionsContainer';
import { useHomepageProductLists } from '@/retention/Homepage/b2c/hooks/useHomeProductLists';
import { ListingProductsMemo } from '@/retention/Homepage/b2c/modules/ListingProducts/ListingProducts';

import parentStyles from '../Container/container.module.scss';
import styles from './SuggestedAndRecentlyViewedProducts.module.scss';

export const SuggestedAndRecentlyViewedProducts = () => {
  const [isHPB2CBlackfridayUpperPromo] = useFlags([
    HP_B2C_BLACKFRIDAY_UPPER_PROMO,
  ]);
  const { hasSuggestedOrRecommendedProducts, productLists } =
    useHomepageProductLists();
  const [selectedProducts, suggestedProducts] = productLists;
  const hasSuggestedProducts =
    hasSuggestedOrRecommendedProducts && suggestedProducts;
  return (
    <>
      <ListingProductsMemo {...selectedProducts} />
      <HomepageTertiaryCampaignsSection />
      {isHPB2CBlackfridayUpperPromo && (
        <SecondaryPromotionsContainer
          className={parentStyles.secondaryPromotions}
        />
      )}
      {hasSuggestedProducts && (
        <ListingProductsMemo
          className={classnames(
            isHPB2CBlackfridayUpperPromo && styles.tmpSectionAdapations,
          )}
          {...suggestedProducts}
        />
      )}
    </>
  );
};
